import * as React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import Seo from "../components/seo"
import SplitSection from "../components/SplitSection"
import Form from "../components/form"
import Footer from "../components/footer"

import Logo from "../components/logo"

const FormWrapper = ({ contactFormDescription, inputs }) => {
  return (
    <div className='flex flex-c flex-s-b h-100-p over-h'>
      <div className=''>
        <h2 className='c-2A2A2A m-w-500 d-n-b'>{contactFormDescription}</h2>
      </div>
      <Form inputs={inputs} name='contact' classEnquiry='m-b-180-br' linksClass='m-b-120-br' generalClass='m-b-0-br' memberhsipsClass='m-b-0-br' classWrapper='m-b-0-br' />
    </div>
  )
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputs: [
        {
          name: "Name",
          required: true,
          type: "text",
          title: "PLEASE PROVIDE YOUR Full Name",
          placeholder: "YOUR NAME",
        },
        {
          name: "email",
          required: false,
          type: "text",
          title: "PLEASE PROVIDE YOUR email",
          placeholder: "YOUR EMAIL",
        },
        {
          name: "phone",
          required: true,
          type: "text",
          title: "PLEASE PROVIDE YOUR phone Number",
          placeholder: "YOUR PHONE NUMBER",
        },
        {
          name: "reason",
          required: true,
          type: "text",
          title: "PLEASE PROVIDE YOUR REASON FOR ENQUIRY",
          placeholder: "REASON FOR YOUR ENQUIRY",
        },
      ],
      currentIndex: 0,
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            datoCmsContact {
              contactHeading
              contactParagraph
              contactFormDescription
              useLinkInstead
              link
            }
          }
        `}
        render={({ datoCmsContact }) => (
          <Layout>
            <Seo title='Contact' />
            <div className='relative  back-white  '>
              <Header className='black w-b' isLink={datoCmsContact.useLinkInstead} link={datoCmsContact.link}/>
              <div data-scroll-container id='scroll-container' className='data-scroll-container'>
                <div className='flex flex-c p-t-50 '>
                  <SplitSection right={<FormWrapper inputs={this.state.inputs} contactFormDescription={datoCmsContact.contactFormDescription} />} className='' alStart classRight='c-d-n-b ' wrapperClassName='stack' subWrapperClassName='b-b-1-br'>
                    <div className='flex flex-c  flex-s-b h-100-p'>
                      <h1 className='c-2A2A2 al-left m-b-120-br form-heading-0'>{datoCmsContact.contactHeading}</h1>
                      <h2 className='alt m-w-500'>{datoCmsContact.contactParagraph}</h2>
                    </div>
                  </SplitSection>
                  <Footer />
                </div>
              </div>
            </div>
          </Layout>
        )}
      />
    )
  }
}
